<template>
  <div class="text-base">
    <GlobalHeader />
    <main>
      <slot />
    </main>
    <GlobalFooter />
  </div>
</template>

<script setup lang="ts">
// preload the config for use in header & footer
await useConfigStore().fetchConfig()
</script>
